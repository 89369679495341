import { SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const EventMarketFields = [
  {
    type: SELECT,
    name: "commentaryId",
    label: "Commentary Type",
    options: [{ label: "Select a Commentary Type", value: "0" }],
    defaultValue: "0",
    isRequired: true,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SELECT,
    name: "marketTemplateId",
    label: "Market Template",
    options: [{ label: "Select a Market Template", value: "0" }],
    defaultValue: "0",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SELECT,
    name: "inningsId",
    label: "Inning",
    options: [{ label: "Select a Innings Id", value: "0" }],
    defaultValue: "0",
    isRequired: true,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SELECT,
    name: "teamId",
    label: "Team",
    options: [{ label: "Select a Team Id", value: "0" }],
    defaultValue: "0",
    isRequired: true,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "marketName",
    label: "Market",
    isRequired: true,
    regex: /^[a-zA-Z0-9 ]{0,100}$/,
    regexErrorMessage: "Max allowed Characters 100, No Spacial Character",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "margin",
    label: "Margin",
    isRequired: true,
    regexErrorMessage: "Please Enter Margin",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SWITCH,
    name: "isPredefineMarket",
    label: "Is PredefineMarket",
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SWITCH,
    name: "isAutoCancel",
    label: "Is AutoCancel",
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isOver",
    label: "Over",
    type: SWITCH,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 1, lg: 1 },
  },
  {
    name: "over",
    label: false,
    dependsOnField: "isOver",
    dependsOnValue: true,
    type: TEXT,
    placeholder: "Overs",
    fieldColspan: { xs: 12, md: 3, lg: 3 },
  },
  {
    name: "isPlayer",
    label: "Player",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 1, lg: 1 },
  },
  {
    name: "playerName",
    label: false,
    dependsOnField: "isPlayer",
    dependsOnValue: true,
    type: TEXT,
    placeholder: "Player Name",
    fieldColspan: { xs: 12, md: 3, lg: 3 },
  },
  {
    name: "createType",
    label: "Create Type",
    options: [
      { label: "Select Create Type", value: "0" },
      { label: "Ball", value: 1 },
      { label: "Over", value: 2 },
      { label: "Wicket", value: 3 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "create",
    label: "Create",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter value",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 1, lg: 1 },
  },
  {
    name: "createRefId",
    label: false,
    dependsOnField: "createType",
    dependsOnValue: 1,
    type: TEXT,
    placeholder: "Create Ref Id",
    fieldColspan: { xs: 12, md: 3, lg: 3 },
  },
  {
    name: "autoOpenType",
    label: "Auto Open Type",
    options: [
      { label: "Select Auto Open Type", value: "0" },
      { label: "Ball", value: 1 },
      { label: "Over", value: 2 },
      { label: "Wicket", value: 3 },
    ],
    type: SELECT,
    defaultValue: false,
    isRequired: true,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "autoOpen",
    label: "Auto Open",
    isRequired: true,
    regexErrorMessage: "Please Enter Value",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 1, lg: 1 },
  },
  {
    name: "openRefId",
    label: false,
    dependsOnField: "autoOpenType",
    dependsOnValue: 1,
    type: TEXT,
    placeholder: "Open Ref Id",
    fieldColspan: { xs: 12, md: 3, lg: 3 },
  },
  {
    name: "autoCloseType",
    label: "Auto Close Type",
    isRequired: true,
    options: [
      { label: "Select Auto Close Type", value: "0" },
      { label: "Ball", value: "1" },
      { label: "Over", value: "2" },
      { label: "Wickets", value: "3" },
    ],
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "beforeAutoClose",
    label: "Before Auto Close",
    isRequired: true,
    regexErrorMessage: "Please Enter Value",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "autoSuspendType",
    label: "Auto Suspend Type",
    isRequired: true,
    options: [
      { label: "Select Auto Suspend Type", value: "0" },
      { label: "Ball", value: "1" },
      { label: "Over", value: "2" },
      { label: "Wicket", value: "3" },
    ],
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "beforeAutoSuspend",
    label: "Before Auto Suspend",
    isRequired: true,
    regexErrorMessage: "Please Enter Before Auto Suspend",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "marketTypeId",
    label: "Market Type",
    isRequired: true,
    options: [{ label: "Select Market Type", value: "0" }],
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "marketTypeCategoryId",
    label: "Category Type",
    isRequired: true,
    options: [{ label: "Select Category Type", value: "0" }],
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SWITCH,
    name: "isAutoResultSet",
    label: "Auto Result Set",
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 1, lg: 1 },
  },
  {
    name: "autoResultType",
    label: false,
    dependsOnField: "isAutoResultSet",
    options: [
      { label: "Select Auto Open Type", value: "0" },
      { label: "Ball", value: "1" },
      { label: "Over", value: "2" },
      { label: "Wickets", value: "3" },
    ],
    dependsOnValue: true,
    type: SELECT,
    fieldColspan: { xs: 12, md: 3, lg: 3 },
  },
  {
    name: "autoResultafterBall",
    label:"Auto Result After Ball",
    dependsOnField: "isAutoResultSet",
    dependsOnValue: true,
    type: TEXT,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 }
  },
  {
    type: SWITCH,
    name: "isBallStart",
    label: "Is Ball Start",
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "afterWicketAutoSuspend",
    label: "After Wicket Auto Suspend",
    isRequired: true,
    regexErrorMessage: "Please Enter After Wicket Auto Suspend",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "afterWicketNotCreated",
    label: "After Wicket Not Created",
    isRequired: true,
    regexErrorMessage: "Please Enter After Wicket Not Created",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "actionType",
    label: "Action Type",
    isRequired: true,
    options: [
      { label: "Select Action Type", value: "0" },
      { label: "winClose", value: "1" },
      { label: "winCloseCancel", value: "2" },
      { label: "winMustClose", value: "3" },
      { label: "winMustCloseCancel", value: "4" },
      { label: "dlsCloseMarket", value: "5"},
      { label: "dlsCloseCancelMarket", value: "6"},
    ],
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "templateType",
    label: "Template Type",
    options: [
      { label: "Select Template Type", value: "0" },
      { label: "Prematch", value: 1 },
      { label: "Inplay", value: 2 },
      { label: "Prematch & Inplay", value: 3 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: TEXT,
    name: "delay",
    label: "Market Delay",
    isRequired: true,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SWITCH,
    name: "isActive",
    label: "Is Active",
    defaultValue: true,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: SWITCH,
    name: "isAllow",
    label: "Is Allow",
    defaultValue: true,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
];
