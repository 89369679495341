module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  loadInit: {
    DP_SOCKET_URL: 'DPSOCKETURL',
    DP_API_KEY: 'DPAPIXKEY',
    DP_API_URL: 'DPAPIURL',
    SCORECARD_FRAME_URL: 'SCORECARDFRAMEURL',
    ENABLE_LOGROCKET: "ENABLELOGROCKET",
    LOG_ROCKET_AP_ID: "LOGROCKETAPPID"
  }
}
